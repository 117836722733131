import { useTheme } from "@mui/material/styles";

const ActivityTrackerSVG = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="910.91505"
      height="727.46554"
      viewBox="0 0 910.91505 727.46554"
    >
      <path
        d="M896.56784,736.779a4.09761,4.09761,0,0,1,.22949-.34,6.95859,6.95859,0,0,1,.76025-1.35l25.16016-35.2,20.73-28.99h35.35986l-2.5,3.49-4.45019,6.23-13.78955,19.28-29.5503,41.33,27.39014,55.02c1.12012,2.24-.79,5.46-4.24023,7.18l-13.21,6.58a9.71164,9.71164,0,0,1-4.23,1.06c-1.85987,0-3.40967-.69-4.06006-2l-34.04981-68.4A3.96261,3.96261,0,0,1,896.56784,736.779Z"
        transform="translate(-144.54248 -86.26723)"
        fill={color}
      />
      <path
        d="M944.14975,734.67051,908.529,721.43565a6.50839,6.50839,0,0,1-3.82922-8.35743l10.44873-28.12158a6.50873,6.50873,0,0,1,8.35693-3.8291l35.62073,13.23486a6.5084,6.5084,0,0,1,3.82922,8.35743l-10.44873,28.12158a6.49918,6.49918,0,0,1-8.35693,3.8291Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <path
        d="M913.86066,719.03843a1.99366,1.99366,0,0,1-.70435-.1289h-.00024a2.00295,2.00295,0,0,1-1.16406-2.57813l13.07885-34.61133a2.00011,2.00011,0,0,1,3.74195,1.41407L915.734,717.74547A2.00366,2.00366,0,0,1,913.86066,719.03843Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#ff"
      />
      <path
        d="M971.33737,805.919V680.90879a1.96788,1.96788,0,0,1,.02185-.34766,7.44252,7.44252,0,0,1,4.76367-6.63671,7.31307,7.31307,0,0,1,2.71448-.51563h14.76025a7.50836,7.50836,0,0,1,7.5,7.5V805.919a7.50835,7.50835,0,0,1-7.5,7.5H978.83737A7.50835,7.50835,0,0,1,971.33737,805.919Z"
        transform="translate(-144.54248 -86.26723)"
        fill={color}
      />
      <path
        d="M963.3276,727.899v-30a6.50745,6.50745,0,0,1,6.5-6.5h38a6.50745,6.50745,0,0,1,6.5,6.5v30a6.50744,6.50744,0,0,1-6.5,6.5h-38A6.50745,6.50745,0,0,1,963.3276,727.899Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <path
        d="M992.40241,731.23277a2.00213,2.00213,0,0,1-2-2v-37a2,2,0,0,1,4,0v37A2.00213,2.00213,0,0,1,992.40241,731.23277Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#fff"
      />
      <path
        d="M924.0682,585.82334,814.7297,529.45371a7.51681,7.51681,0,0,1-3.22656-10.10351l6.7605-13.1211a7.51613,7.51613,0,0,1,10.103-3.22461L937.708,559.3751A7.10577,7.10577,0,0,1,939.226,560.422a7.47123,7.47123,0,0,1,1.7063,9.05616l-6.76086,13.1206a7.47761,7.47761,0,0,1-4.386,3.708,7.77128,7.77128,0,0,1-2.29773.352A7.44214,7.44214,0,0,1,924.0682,585.82334Z"
        transform="translate(-144.54248 -86.26723)"
        fill={color}
      />
      <path
        d="M926.19381,589.06455l-53.15844-20.98779a7.50864,7.50864,0,0,1-3.34717-10.05713l12.12231-24.98438a7.53735,7.53735,0,0,1,4.33985-3.751,7.514,7.514,0,0,1,5.79626.457l48.00391,29.1001a2.18527,2.18527,0,0,1,.51135.356c1.22327,1.10108,1.83289,3.74463,2.322,5.86622l.07752.33007c.13354.56934.2489,1.06055.366,1.40479l.03955.13183c.04273.14014.08631.28516.11573.418a7.46786,7.46786,0,0,1-.57825,5.15479L936.18466,585.693a7.58376,7.58376,0,0,1-6.72717,4.146A7.19913,7.19913,0,0,1,926.19381,589.06455Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <circle cx="820.86298" cy="380.80958" r="53.51916" fill={color} />
      <path
        d="M968.52989,495.9128l.05567-2c-3.7207-.10352-7.001-.33692-9.46582-2.1377a6.148,6.148,0,0,1-2.38135-4.52832,3.51438,3.51438,0,0,1,1.15283-2.89453c1.63623-1.38183,4.269-.93457,6.188-.05469l1.65479.75879-3.17334-23.19043-1.98145.27149,2.69922,19.72656c-2.60742-.7666-5.02344-.43652-6.67822.96094a5.471,5.471,0,0,0-1.86035,4.49219,8.13263,8.13263,0,0,0,3.20019,6.07324C961.107,495.70479,965.22277,495.82,968.52989,495.9128Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <rect
        x="795.46594"
        y="377.57135"
        width="10.77148"
        height="2"
        fill="#2f2e41"
      />
      <rect
        x="829.46594"
        y="377.57135"
        width="10.77148"
        height="2"
        fill="#2f2e41"
      />
      <path
        d="M916.61837,700.37363c-3.5343-.2041-6.29077-6.01513-6.29077-13.30224V593.399a54.36262,54.36262,0,0,1,1.70569-13.52441l.00025-.001c.41247-1.58886.90161-3.16845,1.45312-4.69238a53.86523,53.86523,0,0,1,17.0249-23.87939c.87793-.7168,1.79639-1.41065,2.72791-2.06153a53.64212,53.64212,0,0,1,14.81018-7.335,9.37219,9.37219,0,0,1-.22205-2.00635,9.51081,9.51081,0,0,1,9.5-9.5h14a9.51081,9.51081,0,0,1,9.5,9.5,9.3728,9.3728,0,0,1-.22143,2.00245A54.03126,54.03126,0,0,1,1018.3276,593.399v93.67237c0,7.47363-2.8551,13.32763-6.5,13.32763h-95A.74093.74093,0,0,1,916.61837,700.37363Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <path
        d="M978.46151,708.92438a7.51092,7.51092,0,0,1-.9835-3.57572L975.37424,582.355a7.48958,7.48958,0,0,1,2.10409-5.34169l.00176-.00171a7.49632,7.49632,0,0,1,5.26326-2.28966l14.75988-.24764a7.495,7.495,0,0,1,7.57842,6.62095,5.95524,5.95524,0,0,1,.04837.74176l2.1117,123.00221a7.51848,7.51848,0,0,1-7.37685,7.62463l-14.755.25433a7.52937,7.52937,0,0,1-6.64839-3.79384Z"
        transform="translate(-144.54248 -86.26723)"
        fill={color}
      />
      <path
        d="M972.25818,624.46265a7.50794,7.50794,0,0,1-.96461-3.208l.63044-56.98316a7.50884,7.50884,0,0,1,6.89142-8.06357l14.7133-1.1526a7.547,7.547,0,0,1,5.023,1.428c.15081.11428.30177.25358.44881.35319a14.035,14.035,0,0,0,1.14833.86009l.31886.22647c2.11482,1.49743,4.51256,3.19425,4.62854,4.84783l8.85176,55.59545a7.38239,7.38239,0,0,1-1.0961,4.52576,6.81529,6.81529,0,0,1-.67724.93941,6.47467,6.47467,0,0,1-.72111.72433,7.42677,7.42677,0,0,1-4.40849,1.88415l-27.69061,1.78652a7.50756,7.50756,0,0,1-7.09631-3.76383Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <path
        d="M920.40241,687.23277a2.00213,2.00213,0,0,1-2-2v-97a2,2,0,0,1,4,0v97A2.00213,2.00213,0,0,1,920.40241,687.23277Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#fff"
      />
      <path
        d="M1013.944,454.36317l-92.97388-34.01261a4,4,0,0,1-2.38227-5.13077l.31452-1.487c7.26106-19.84821,31.328-32.33975,51.17618-25.07868l28.61019,10.46646c19.84821,7.26107,28.47469,30.764,21.21363,50.61222l-.82761,2.24813A4,4,0,0,1,1013.944,454.36317Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <path
        d="M1018.95221,456.25047c-129.5874,4.78152-140.91064-11.50085-101.42627-37.10468l8.37647-15.413,96,35Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#2f2e41"
      />
      <ellipse
        cx="985.40241"
        cy="394.23277"
        rx="4.5"
        ry="8.5"
        transform="translate(179.64766 1138.16775) rotate(-73.26954)"
        fill="#2f2e41"
      />
      <path
        d="M428.12825,86.26723c-92.916,0-168.497,75.581-168.497,168.497a167.59284,167.59284,0,0,0,14.77387,68.98941c26.40679,58.61,85.36724,99.5076,153.72314,99.5076a168.59048,168.59048,0,0,0,168.497-168.497C596.62532,161.84831,521.03078,86.26723,428.12825,86.26723ZM567.5359,322.5A155.17518,155.17518,0,0,1,428.12825,409.7815c-65.68683,0-121.96484-41.07287-144.48956-98.88751A153.876,153.876,0,0,1,273.111,254.76423C273.111,169.289,342.65309,99.747,428.12825,99.747s155.01721,69.542,155.01721,155.01724A153.93514,153.93514,0,0,1,567.5359,322.5Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#e6e6e6"
      />
      <path
        d="M428.12848,423.76109A169.20025,169.20025,0,0,1,275.59174,327.495a7.2787,7.2787,0,0,1,.54761-7.2334l.09033-.13233a7.17286,7.17286,0,0,1,12.37878.99024c25.5636,53.55615,80.32837,88.16211,139.51978,88.16211a155.14522,155.14522,0,0,0,133.61865-76.97608,7.17631,7.17631,0,0,1,6.259-3.58789,7.04924,7.04924,0,0,1,6.16211,3.59229l.07861.13818a7.26593,7.26593,0,0,1-.03808,7.22168A169.6463,169.6463,0,0,1,428.12848,423.76109Z"
        transform="translate(-144.54248 -86.26723)"
        fill={color}
      />
      <path
        d="M390.15022,270.1757a19.77648,19.77648,0,1,1-13.87091,5.80713,19.51462,19.51462,0,0,1,13.87091-5.80713m0-8.42487A28.08785,28.08785,0,1,0,418.223,289.85374a28.08787,28.08787,0,0,0-28.07282-28.10291Zm58.52265-40.16848a9.58,9.58,0,0,0,9.62841-9.53122q.00028-.0486,0-.09719a9.47824,9.47824,0,0,0-9.38745-9.56822q-.09036-.00087-.18078,0a9.59855,9.59855,0,0,0-.181,19.19625Q448.6125,221.58254,448.67287,221.58235Zm20.12939,48.59332a19.7765,19.7765,0,1,1-13.87091,5.80713,19.51462,19.51462,0,0,1,13.87091-5.80713m0-8.42484a28.07282,28.07282,0,1,0,28.07282,28.07282,28.07281,28.07281,0,0,0-28.07282-28.07282Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#e6e6e6"
      />
      <path
        d="M463.17561,240.8993H448.73305L439.85689,222.816A9.41355,9.41355,0,0,0,424.963,220.8301L403.3893,241.62147a9.79742,9.79742,0,0,0-2.79828,6.73987c0,5.23544,3.7912,7.101,5.5664,8.15405,13.69037,7.88324,18.50458,8.75583,18.50458,12.69745V289.0413a4.81421,4.81421,0,0,0,9.62842,0h0V261.96147c0-3.97171-9.147-7.22132-14.5329-11.43372l14.71344-15.4957c5.62659,8.57529,8.21424,15.4957,11.43372,15.4957h17.27093a4.8142,4.8142,0,1,0,.01551-9.62839h-.01551Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#e6e6e6"
      />
      <path
        d="M292.59863,514.12375c-4.14722,0-7.58081,12.07434-8.24628,27.90612H204.56891c-.6655-15.83184-4.09906-27.90612-8.24631-27.90612-4.62354,0-8.37183,14.99285-8.37183,33.48736s3.74829,33.48724,8.37183,33.48724c4.14725,0,7.58081-12.07434,8.24631-27.90612h79.78338c.66547,15.83184,4.09906,27.90612,8.24628,27.90612,4.62353,0,8.37182-14.99285,8.37182-33.48724S297.22216,514.12375,292.59863,514.12375Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#e6e6e6"
      />
      <path
        d="M331.46167,498.16922a99.77255,99.77255,0,1,0,12.644,48.581A99.7726,99.7726,0,0,0,331.46167,498.16922Zm-87.1601,140.39166a91.35849,91.35849,0,1,1,.92169.00006Q244.76239,638.56324,244.30157,638.56088Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#e6e6e6"
      />
      <path
        d="M244.35028,647.05455a100.09561,100.09561,0,0,1-53.6969-15.5918,4.48982,4.48982,0,0,1-1.76721-2.14941l-.05029-.12989a4.40313,4.40313,0,0,1,1.34607-5.06738,4.34494,4.34494,0,0,1,5.166-.31592A91.26948,91.26948,0,0,0,324.549,503.223a4.34957,4.34957,0,0,1,.67261-5.13378,4.40914,4.40914,0,0,1,5.15393-.99122l.12512.0586a4.4564,4.4564,0,0,1,2.0149,1.88818A100.267,100.267,0,0,1,244.397,647.05455Z"
        transform="translate(-144.54248 -86.26723)"
        fill={color}
      />
      <path
        d="M611.9087,446.96406a99.80413,99.80413,0,1,0-.03625,199.60825h.03625c.647,0,1.28052,0,1.92761-.02692A99.79994,99.79994,0,0,0,611.9087,446.964ZM632.24964,636.3007A91.81821,91.81821,0,1,1,611.85475,454.944h.054A91.81841,91.81841,0,0,1,632.24964,636.3007Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#e6e6e6"
      />
      <path
        d="M611.90875,647.07213a100.30546,100.30546,0,0,1-21.23632-198.33692,4.59848,4.59848,0,0,1,1.80786-.02344l.20825.03955a4.44178,4.44178,0,0,1,.10132,8.7002A91.32829,91.32829,0,0,0,611.92243,638.079c.88208-.0083,1.7395-.01221,2.61035-.0376a4.13764,4.13764,0,0,1,4.21216,3.24219,4.00259,4.00259,0,0,1-2.272,4.63818v.00049q-.88146.40429-1.7705.793a4.10692,4.10692,0,0,1-1.584.35059C612.71613,647.07213,612.315,647.07213,611.90875,647.07213Z"
        transform="translate(-144.54248 -86.26723)"
        fill={color}
      />
      <path
        d="M661.145,542.52488a12.19813,12.19813,0,0,0-12.17865,8.31281h-9.82886l-7.17578-21.578a4.04334,4.04334,0,0,0-3.84058-2.77936h-.0758a3.99554,3.99554,0,0,0-3.79,2.931l-11.24377,39.2395-13.2146-79.3634a4.05669,4.05669,0,0,0-7.93384-.32846l-13.39154,61.87869H563.463a4.04272,4.04272,0,0,0,0,8.08545h18.1922a4.02974,4.02974,0,0,0,3.91638-3.05731l9.44983-46.03638,12.96192,77.79688a4.01359,4.01359,0,0,0,3.76477,3.36047h.22742a4.05538,4.05538,0,0,0,3.89111-2.931l12.55768-43.8634,3.96685,11.95135a4.07645,4.07645,0,0,0,3.84058,2.77936H649.1432a12.13065,12.13065,0,1,0,12.00177-16.3982Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#e6e6e6"
      />
      <path
        d="M1054.45752,813.73277h-183a1,1,0,0,1,0-2h183a1,1,0,0,1,0,2Z"
        transform="translate(-144.54248 -86.26723)"
        fill="#3f3d56"
      />
    </svg>
  );
};

export default ActivityTrackerSVG;
